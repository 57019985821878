import React from 'react';
import styled, { css } from 'styled-components';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Grid } from '../../layout/Grid';
import { Text } from '../../components/Eyebrow/Eyebrow.styles';
import { CardListProps } from '.';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;
  padding-bottom: ${spacing.space24};
`;

export const Title = styled(Text)`
  margin-bottom: 0;
`;

export const ListItem = styled.div`
  min-width: calc(100vw - ${spacing.space72});
  scroll-snap-align: start;

  @media ${breakpointUp.tablet} {
    min-width: 0;
  }
`;

export const List = styled(Grid)<CardListProps>`
  align-items: start;
  grid-auto-flow: row;
  grid-auto-columns: minmax(min-content, max-content);
  scroll-snap-type: x mandatory;
  grid-column: 1 / -1;
  row-gap: ${({ mobile }) => mobile?.rowGap};

  ${({ scrollMobile, children }) =>
    scrollMobile &&
    React.Children.count(children) > 1 &&
    css`
      overflow-x: auto;
      overflow-y: hidden;
      grid-auto-flow: column;
      margin: 0 -${spacing.space16};
      padding-right: ${spacing.space16};
      column-gap: 0;

      ${ListItem} {
        padding-left: ${spacing.space16};
      }
    `}

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &.mobile-one-column,
  &.mobile-one-column-highlight {
    grid-template-columns: repeat(1, 1fr);
  }

  &.mobile-two-column,
  &.mobile-two-column-highlight {
    grid-template-columns: repeat(2, 1fr);
  }

  &.mobile-three-column,
  &.mobile-three-column-highlight {
    grid-template-columns: repeat(3, 1fr);
  }

  &.mobile-four-column,
  &.mobile-four-column-highlight {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${breakpointUp.tablet} {
    row-gap: ${({ tablet }) => tablet?.rowGap};

    ${({ scrollMobile, children }) =>
      scrollMobile &&
      React.Children.count(children) > 1 &&
      css`
        overflow-x: visible;
        overflow-y: visible;
        grid-auto-flow: row;
        margin: 0;
        padding: 0;
        column-gap: ${spacing.space16};

        ${ListItem} {
          padding-left: 0;
        }
      `}

    &.tablet-one-column,
    &.tablet-one-column-highlight {
      grid-template-columns: repeat(1, 1fr);
    }

    &.tablet-two-column,
    &.tablet-two-column-highlight {
      grid-template-columns: repeat(2, 1fr);
    }

    &.tablet-three-column,
    &.tablet-three-column-highlight {
      grid-template-columns: repeat(3, 1fr);
    }

    &.tablet-four-column,
    &.tablet-four-column-highlight {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media ${breakpointUp.desktop} {
    row-gap: ${({ desktop }) => desktop?.rowGap};

    &.desktop-one-column,
    &.desktop-one-column-highlight {
      grid-template-columns: repeat(1, 1fr);
    }

    &.desktop-two-column,
    &.desktop-two-column-highlight {
      grid-template-columns: repeat(2, 1fr);
    }

    &.desktop-three-column,
    &.desktop-three-column-highlight {
      grid-template-columns: repeat(3, 1fr);
    }

    &.desktop-four-column,
    &.desktop-four-column-highlight {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media ${breakpointUp.desktopLarge} {
    row-gap: ${({ desktopLarge }) => desktopLarge?.rowGap};

    &.desktop-large-one-column,
    &.desktop-large-one-column-highlight {
      grid-template-columns: repeat(1, 1fr);
    }

    &.desktop-large-two-column,
    &.desktop-large-two-column-highlight {
      grid-template-columns: repeat(2, 1fr);
    }

    &.desktop-large-three-column,
    &.desktop-large-three-column-highlight {
      grid-template-columns: repeat(3, 1fr);
    }

    &.desktop-large-four-column,
    &.desktop-large-four-column-highlight {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;
